@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Syne:wght@400..800&display=swap");

:root {
  /*--darkBackColor: #eeeef7;
  --baseBackColor: #d8e1e9;
  --darkItemColor: #eeeef7;
  --darkItemColorHover: #1d1d1d;
  --warningColor: #ee0000;
  --activeTextColor: #252525;
  --highlightColorDark: #82d9ff;
  --highlightColorDarkHover: #72d5ff;
  --windowBorderRadius: 8px;
  --itemBorderRadius: 4px;
  --tinyBorderRadius: 2px;
  --headerPageDistance: 15px;
  --buttonDisabled: #d4d4d4;#4CAF50
  --itemBorderColor: #a8a8a8;
  --vectorFilter: brightness(40%);
  --baseItemColorHover: #222222;*/
  --baseTextColor: #3b3b3b;
  --activeTextColor: #ffffff;
  --highlightColor: #595386;
  --darkBackColor: #15151f;
  --darkBackColor-semitransparent: #15151f80;
  --buttonDisabled-BackColor: #3b4353;
  --buttonDisabled-TextColor: #a6a9af;
  --warningColor: #ffbb00;
  --redColor: #ff3030;
  --inputBackColor: #00093512;
  --activeGreenColor: #4caf50;
  --linkColor: #b6cdff;
}

html,
body {
  height: 100%; /* This should remain to fill the height correctly */
  margin: 0;
  padding: 0;
  width: 100%;
}

body {
  background-color: var(--darkBackColor);
  color: var(--activeTextColor);
  display: flex;
  flex-direction: column; /* Ensures that the content flows vertically */
  justify-content: flex-start; /* Aligns content to the start, preventing cut-off */
  min-height: 100vh; /* Ensures at least the full height of the viewport */
  overflow: auto; /* Ensures content can scroll */
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 200;
  font-style: normal;
  font-variation-settings: "slnt" 0;
}
h1 {  
  font-size: 22px;
  font-weight: 700;
  margin: 15px 0;
}
.App {
  flex: 1 0 auto; /* Allows the App to grow and shrink as needed */
  display: flex;
  flex-direction: column; /* Stacks children vertically */
  justify-content: center; /* Vertically centers content if it fits within the viewport */
  align-items: center; /* Horizontally centers content */
  text-align: center;
  width: 100%;
  /* max-width: 1200px;  Limit the max width if necessary */
  min-height: 100vh; /* Makes minimum height at least the viewport height */
}
/* 
@media (min-height: 100vh) {
  .App {
    justify-content: center; 
  }
} */

a {
  color: var(--linkColor);
  cursor: pointer;
}

.logo {
  background-image: url("./assets/logo.png");
  position: fixed;
  left: 18px;
  top: 18px;
  background-repeat: no-repeat;
  background-size: cover;
  width: 40px;
  height: 40px;
  z-index: 102;
  cursor: pointer;
}
.menu {
  display: flex;
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: unset;
  margin: 0;
  width: 100%;
  height: 68px;
  background: var(--darkBackColor-semitransparent); /* White with 50% opacity */
  /* Blur effect on the content behind the element */
  backdrop-filter: blur(5px);
  /*box-shadow: 0 0 5px 8px var(--darkBackColor-semitransparent);*/
  justify-content: right;
  padding-right: 0px;
  z-index: 100;
}

.menuButton {
  color: var(--activeTextColor);
  height: 35px;
  line-height: 35px;
  width: auto;
  text-align: center;
  border: none;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  margin: 20px 40px 30px 0px;
  justify-self: right;
}

.menuButton:hover {
  filter: brightness(0.9);
}
.menuButtonActive {
  border-bottom: solid 1px var(--highlightColor);
}

.factoryContainer {
  min-height: 92vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  overflow: hidden;
}

.deploymentContainer {
  margin-top: 100px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.outerCardContainer {
  margin-top: 120px;
  margin-bottom: 80px;
  display: flex;
  flex-direction: row;
}
.cardContainer {
  width: 80%;
  min-width: 180px;
  max-width: 250px;
  height: auto;
  border-radius: 35px;
  padding: 20px;
  margin: 10px 10px;
  background-color: var(--inputBackColor);
  border: 1px solid var(--highlightColor);
}

.cardImage {
  margin: 20px 0;
  height: 90px;
}
.cardImage2 {
  margin: 10px 0;
  height: 120px;
}

.cardTitle {
  font-size: 22px;
  font-weight: 700;
  margin: 15px 0;
}
.cardSubTitle {
  font-size: 18px;
  font-weight: 700;
  margin: 10px 0;
}

.cardDescription {
  font-size: 14px;
  font-weight: 400;
  margin: 10px 0;
}

.cardButton {
  color: var(--activeTextColor);
  height: 40px;
  width: 100%;
  text-align: center;
  border-radius: 20px;
  border: none;
  background-color: var(--highlightColor);
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  margin-top: 10px;
}

.cardButton:hover {
  filter: brightness(0.9);
}

.cardButton:disabled {
  color: var(--buttonDisabled-TextColor);
  background-color: var(--buttonDisabled-BackColor);
  filter: brightness(0.9);
  cursor: default;
}

.smallButton {
  color: var(--activeTextColor);
  height: 20px;
  width: 70px;
  text-align: center;
  border-radius: 10px;
  border: none;
  background-color: var(--highlightColor);
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  filter: brightness(0.9);
}

.smallButton.hovered {
  filter: brightness(0.8);
}
.smallButton:hover {
  filter: brightness(0.8);
}
.smallButton:disabled {
  color: var(--buttonDisabled-TextColor);
  background-color: var(--buttonDisabled-BackColor);
  filter: brightness(0.6);
  cursor: default;
}

.importExportButton {
  color: var(--activeTextColor);
  height: 30px;
  width: 100%;
  text-align: center;
  border-radius: 15px;
  border: none;
  background-color: var(--highlightColor);
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  filter: brightness(0.6);
}

.importExportButton:hover {
  filter: brightness(0.9);
}

.importExportText {
  font-size: 14px;
  font-weight: 400;
  padding: 1vmin 4vmin;
  color: var(--buttonDisabled-TextColor);
  text-align: center;
  line-height: 1.3;
}
.inputTitle {
  text-align: left;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 5px;
}
.inputContainer {
  width: 80%;
  min-width: 180px;
  max-width: 320px;
  height: auto;
  border-radius: 35px;
  padding: 20px 30px;
  margin: 80px auto 20px auto;
  background-color: var(--inputBackColor);
  border: 1px solid var(--highlightColor);
}

.singleInputContainer {
  width: 100%;
  margin: 0px 0 20px 0;
}

.inputField {
  padding: 0;
  margin: 0;
  left: 0;
  width: 95% !important;
  font-size: 16px;
}
.formattedNumber {
  margin-top: 5px;
  font-size: 12px;
  font-weight: 300;
}
.errorText {
  margin-bottom: 5px;
  margin-top: 20px;
  font-size: 12px;
  font-weight: 300;
  color: var(--warningColor);
}
.priceText {
  margin-bottom: 5px;
  font-size: 12px;
  font-weight: 600;
  color: var(--activeTextColor);
}
.textRedStrikeThrough {
  text-decoration: line-through;
  margin-bottom: 5px;
  font-size: 12px;
  font-weight: 400;
  color: var(--buttonDisabled-TextColor);
}

.checkBoxContainer {
  width: 100%;
  max-width: 700px;
  max-height: 34px;
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  padding: 5px 0;
}
.checkBoxText {
  text-align: left;
  flex-grow: 1;
  font-size: 14px;
  font-weight: 400;
  margin: 0 0px;
  line-height: 30px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--baseTextColor);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: var(--activeTextColor);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: var(--highlightColor);
}
input:focus + .slider {
  box-shadow: 0 0 1px var(--highlightColor);
}
input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
.slider.round {
  border-radius: 34px;
}
.slider.round:before {
  border-radius: 50%;
}


.pageButtonsContainer {
  width: 80%;
  min-width: 180px;
  max-width: 382px;
  height: auto;
  border-radius: 35px;
  display: flex;
  margin: 0px auto 20px auto;
}

.backButton {
  width: 40%;
  height: auto;
  border-radius: 35px;
  padding: 0px 30px;
  text-align: left;
  background-image: url("./assets/left.svg");
  background-size: 25px;
  background-position: -2px -3px;
  background-repeat: no-repeat;
  color: var(--highlightColor);
  font-size: 14px;
  cursor: pointer;
  font-weight: 600;
}

.backButton:hover {
  filter: brightness(0.9);
}

.backButtonDeployments {
  padding: 0px 30px;
  text-align: left;
  background-image: url("./assets/left.svg");
  background-size: 25px;
  background-position: 0px -3px;
  background-repeat: no-repeat;
  color: var(--highlightColor);
  font-size: 14px;
  cursor: pointer;
  font-weight: 600;
  width: 80%;
  min-width: 180px;
  max-width: 382px;
  height: auto;
  border-radius: 35px;
  display: flex;
  margin: 0px auto 20px auto;
}

.backButtonDeployments:hover {
  filter: brightness(0.9);
}

.wideButton {
  width: 60%;
  height: auto;
  border-radius: 35px;
  padding: 0px 30px;
  text-align: right;
  background-image: url("./assets/right.svg");
  background-size: 25px;
  background-position: calc(100% + 2px) -3px;
  background-repeat: no-repeat;
  color: var(--highlightColor);
  font-size: 14px;
  cursor: pointer;
  font-weight: 600;
}

.wideButton:hover {
  filter: brightness(0.9);
}

/*  style.css

  .outerCardContainer{
    margin-top: 90px;
    margin-bottom: 60px;
  }
*/

.headerText {
  position: fixed;
  top: 0px;
  left: 0px;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  padding-left: 100px;
  margin-top: 30px;
  color: var(--activeTextColor);
  z-index: 101;
}

.background {
  background: var(--darkBackColor);
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  background-position: center bottom;
  display: flex;
  flex-direction: column;
}

.firstContainer {
  max-width: 600px;
  height: max-content;
  background-color: var(--inputBackColor);
  border-radius: 35px;
  margin: auto;
  position: relative;
  border: 1px solid var(--highlightColor);
  padding: 20px;
  margin-top: 80px;
}

.secondContainer {
  width: 90%;
  height: max-content;
  background-color: var(--inputBackColor);
  border: 1px solid var(--highlightColor);
  border-radius: 35px;
  position: relative;
  padding: 20px;
  max-width: 600px;
  margin: 80px auto 80px;
}
.copyButton {
  cursor: pointer;
  width: 38px;
  height: 32px;
  border-radius: 12px;
  font-size: 20px;
  line-height: 30px;
  background-color: transparent;
  border: 1px solid var(--highlightColor);
  transition: background-color 0.3s ease; /* Smooth transition for background color */
  margin-top: 6px;
}

.copyButton:hover {
  filter: brightness(0.9);
}

.copyButton.active {
  filter: brightness(1);
}

.container {
  flex-direction: row;
  align-items: center;
  gap: 10px;
  display: flex;
  justify-content: center;
  width: 90%;
  margin: auto;
}

.title {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  color: var(--activeTextColor);
  margin-top: 0;
  margin-bottom: 20px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-variation-settings: "slnt" 0;
}

.boxStyle {
  padding: 5px 0;
  border-radius: 40px;
  width: 180px;
  height: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: solid 1px var(--highlightColor);
}

.boxColor {
  border-radius: 45px;
  height: fit-content;
}

.infoText {
  display: flex;
  font-size: 14px;
  font-weight: 400;
  color: var(--activeTextColor);
}

.infoText1 {
  font-size: 16px;
  font-weight: 400;
}

.value {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 0.5%;
  margin-bottom: 0.5%;
}

.valueNumber {
  display: flex;
  justify-content: flex-end;
  font-size: 22px;
  font-weight: 700;
  color: var(--highlightColor);
  font-weight: 900;
  width: 55%;
  margin-top: 5px;
  margin-bottom: 5px;
}

.valueGwei {
  font-size: 14px;
  font-weight: 400;
  color: var(--highlightColor);
  width: 45%;
  text-align: center;
  padding-top: 11px;
}

.fontsize {
  font-size: 24px;
  font-weight: 600;
}

.dollar {
  font-size: 12px;
  font-weight: 100;
  color: var(--activeTextColor);
}

.container2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 12vh;
  width: 90%;
  gap: 2.5vh;
  margin: auto;
  margin-top: 1%;
}

.box {
  display: flex;
  gap: 3vmin;
  width: 100%;
  max-width: 600px;
  text-align: center;
}

.text {
  font-size: 14px;
  font-weight: 400;
  color: var(--activeTextColor);
  width: 40px;
  min-width: 40px;
  text-align: left;
  line-height: 3.3;
}

.boxStyle2 {
  width: 100%;
  flex-grow: 1; /* Allows the input to grow and fill available space */
  flex-shrink: 1; /* Allows the input to shrink as needed */
  min-width: 0; /* Overrides any default minimum width */
  border-radius: 60px;
  height: 40px;
  border: 1px solid var(--highlightColor);
  font-size: 16px;
  padding-left: 15px;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  margin-bottom: 10px;
  background-color: var(--darkBackColor);
  color: var(--buttonDisabled-TextColor);
}
/*gainsboro*/
.boxStyle2Margin {
  margin-bottom: 0px;
}

.boxStyle2:focus-visible {
  border: 1px solid var(--highlightColor);
  color: var(--activeTextColor);
  font-weight: 600;
  outline: none;
  filter: brightness(0.8);
}

.footerContainer {
  position: relative;
  bottom: 0;
  width: 95%;
  max-width: 600px;
  height: fit-content;
  margin: auto;
}

.footerText {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  color: var(--activeTextColor);
  text-align: center;
  margin: 20px 20px 60px 20px;
}

@media screen and (max-width: 1100px) {
  .valueGwei {
    width: 50%;
  }
}

/*Dropdown*/

.dropdown {
  position: relative;
  display: inline-block;
  width: 100%;
  user-select: none;
}

.dropdown-button {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 5px;
  padding-left: 0px;
  border: 1px solid var(--highlightColor);
  border-radius: 60px;
  background-color: var(--darkBackColor);
  cursor: pointer;
  height: 42px;
  background-image: url("./assets/down.svg");
  background-repeat: no-repeat;
  background-position: calc(100% - 16px);
  background-size: 25px;
}
.dropdown-button:hover {
  filter: brightness(0.9);
  /*transition: ease-in 0.2s;*/
}

.dropdown-list {
  position: absolute;
  margin-top: -187px;
  width: 100%;
  padding: 0px;
  background-color: var(--darkBackColor);
  border: 1px solid var(--highlightColor);
  border-radius: 22px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  z-index: 1;
  overflow: hidden;
}

.dropdown-item {
  list-style: none;
  padding: 5px 0px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.dropdown-item:hover {
  background-color: #504b79;
  transition: ease-in 0.2s;
}

.importExportContainer {
  width: 90%;
  max-width: 400px;
  margin: 20px auto;
  padding: 0px;
  background-color: var(--darkBackColor);
  border: 1px solid var(--highlightColor);
  border-radius: 35px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  z-index: 1;
  overflow: hidden;
}

.userDeployment-list {
  width: 90%;
  max-width: 400px;
  margin: 80px auto 10px auto;
  padding: 0px;
  background-color: var(--darkBackColor);
  border: 1px solid var(--highlightColor);
  border-radius: 35px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  z-index: 1;
  overflow: hidden;
}

.userDeployment-item {
  list-style: none;
  padding: 2vmin 4vmin;
  display: block;
  align-items: center;
  position: relative; /* Positionierungskontext für das Pseudo-Element */
}

.userDeployment-item::before {
  content: ''; /* Notwendig, um das Pseudo-Element anzuzeigen */
  position: absolute;
  left: 10%; /* Zentriert den 50% breiten Rand */
  right: 10%;
  top: 0; /* Positioniert den Rand oben am Element */
  border-top: 1px solid var(--highlightColor); /* Stil des Randes */
  width: 80%; /* Setzt die Breite des Randes auf 50% */
}

.userDeployment-chainItem {
  list-style: none;
  display: flex;
  padding: 1.5vmin 0.5vmin 0.5vmin 0.5vmin;
  align-items: center;
}
.userDeploymentText {
  font-size: 14px;
  font-weight: 600;
  padding: 2px 5px;
  color: var(--activeTextColor);
  text-align: left;
  line-height: 2;
}

.explorerLink {
}

.explorerLink:hover {
  filter: brightness(0.9);
}

.icon {
  width: 20px; /* Control the size of your icons */
  height: 20px; /* Control the size of your icons */
  margin: auto 10px auto;
  margin-right: 15px;
}

.dropdownText {
  font-size: 14px;
  font-weight: 400;
  color: var(--activeTextColor);
  text-align: left;
  line-height: 2;
}
.dropdownButtonText {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: var(--activeTextColor);
  text-align: left;
  line-height: 2;
}

@media screen and (max-width: 624px) {
  .headerText {
    position: absolute;
    top: 0px;
    padding-left: 0px;
    width: 100%;
    text-align: center;
  }
  .menu {
    position: fixed;
    top: unset;
    bottom: 0px;
    height: 50px;
  }
  .logo {
    position: absolute;
  }

  .menuButton {
    margin: 7px auto 30px auto;
    text-align: center;
    width: 33%;
    margin-left: 4px;
    margin-right: 4px;
    user-select: none;
  }
  .outerCardContainer {
    margin-top: 80px;
    margin-bottom: 40px;
    flex-direction: column;
  }
  .firstContainer {
    margin-top: 80px;
  }
  .secondContainer {
    margin-top: 80px;
    width: 87%;
  }
  .container {
    flex-direction: column;
    justify-content: center;
  }
  .boxStyle {
    width: 80%;
  }

  .boxStyle2Converter {
    font-size: 13px;
  }

  .cardContainer {
    padding-left: 36px;
    padding-right: 36px;
  }

  .inputContainer {
    padding: 20px 17px;
  }

  .userDeployment-list {
    width: 89.5%;
  }
}

@media screen and (max-width: 280px) {
  .menuButton {
    font-size: 11px;
    margin-top: 2px;
  }

  .boxStyle2Converter {
    font-size: 10px;
    padding-left: 10px;
  }

  .secondContainer {
    width: 90%;
    padding-left: 12px;
    padding-right: 12px
  }

  .textConverter {
    font-size: 12px;
    min-width: 30px;
    margin-top: 2px;
  }
  
  .inputContainer {
    padding: 20px 22px;
  }

  .cardContainer {
    width: 66.5%;
  }
}
